$(document).ready(function(){

	$(window).load(function(){

		// Global Variables
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.site-header').outerHeight(),
		$quickLinks = $('nav.site-quicklinks').outerHeight(),
		$footerHeight = $('footer.site-footer').outerHeight();


		$(document).on('click', '.mobile-menu.notAnimated', function(){
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$(this).removeClass('notAnimated').addClass('animated');
			$('.menu-container').addClass('show');
			setTimeout(function(){
				$('.menu-container').addClass('animate');
			}, 500);
		});

		$(document).on('click', '.mobile-menu.animated', function(){
			$(this).removeClass('animated').addClass('notAnimated');

			$('.menu-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.menu-container').removeClass('show');
			}, 500);

			setTimeout(function(){
				$('.menu-container .menu-contain nav ul li .sub-menu').removeClass('show');
			}, 700);

		});

		$(document).on('click', '.submenu-button', function(){
			$(this).parent('li').find(' > .sub-menu').addClass('show');
		});
		$(document).on('click', '.submenu-close-button', function(){
			$(this).parent('li').parent('ul').removeClass('show');
		});

		$(document).on('click', 'a[href^="mailto:"].main', function(e){
			e.preventDefault();
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$('.mailto-container').addClass('show');
			setTimeout(function(){
				$('.mailto-container').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.mailto-close-button', function(){
			$('.mailto-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.mailto-container').removeClass('show');
			}, 300);
		});

		$(document).on('click', '.search-btn', function(){
			$('body, html').css({
				'overflow' : 'hidden'
			});
			$('.search-form-template').addClass('show');
			setTimeout(function(){
				$('.search-form-template').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.search-close-button', function(){
			$('body, html').removeAttr('style');
			$('.search-form-template').removeClass('show');
			setTimeout(function(){
				$('.search-form-template').removeClass('animate');
			}, 300);
		});

		$(document).on('click', '.testimonial-item-contain', function(){
			$.ajax({
				url : $('.ajaxlocation').data('ajaxlocation'),
				type : 'POST',
				dataType: 'json',
				data : {
					action : 'request_testimonial',
					id : $(this).data('id')
				},
				beforeSend: function(){
					$('body, html').css({
						'overflow' : 'hidden'
					});
					$('.popup-modal-container').addClass('show');
					$('html').addClass('popupshow');
				},
				success: function(response) {
					
					setTimeout(function(){
						$('.popup-modal-container').addClass('animate');
					}, 300);
					$('.popup-modal-container .popup-body').html(response.content);
			
				}
			});
		});

		$(document).on('click', '.popup-close', function(){
			$('body, html').removeAttr('style');
			$('header.site-header.push-top').removeAttr('style');
			$('.popup-modal-container').removeClass('show');
			$('html').removeClass('popupshow');
			setTimeout(function(){
				$('.popup-modal-container').removeClass('animate');
			}, 300);
		});


		$(document).on('click', '.apply-btn', function(e){
			var position = $(this).data('position');
			e.preventDefault();
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$('.career-container .yourPosition input').val(position);
			$('.career-container').addClass('show');
			setTimeout(function(){
				$('.career-container').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.career-close-button', function(){
			$('.career-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.career-container').removeClass('show');
			}, 300);
		});

		$(document).on('click', '#resumeBtn', function(){
			var input = $('.resume-container .resume input');
			input.click();
		});

		$('.resume-container .resume input').change(function(e){
			var FileName = e.target.files[0];
			$('#resumeBtn').addClass('active').text(FileName.name);
		});

		$(document).on('click', '.expand-term', function(e){
			e.preventDefault();
			$(this).toggleClass('fa-angle-down').toggleClass('fa-angle-up');
			$(this).parent('a').parent('.parent-term').find('.chid-term').first().toggleClass('active');
		});

		$(document).on('click', '.expand-selection-term', function(e){
			$('.archive-sidebar .term-container').toggleClass('active');
		});

		$(document).on('click', '.parent-backdrop .backdrop', function(){
			$('body, html').removeAttr('style');
			$(this).parent('.parent-backdrop').removeClass('show');
			setTimeout(function(){
				$(this).parent('.parent-backdrop').removeClass('animate');
			}, 300);
		});	

	});
	formapply();
	slidetestimonial();
	loadmore();
	loadmores();
	loadmorenews();
	hovering();
	frontCarousel();
	searchProject();
	companyCarousel();
});


function formapply(){
	$('#contform').click(function(){
		$('.applyform').css('display','block');
	});
	$('#exitform').click(function(){
		$('.applyform').css('display','none');
	});
}
function slidetestimonial(){
	var number = 110;
	var count = 0;
	$('#prev').click(function(){
		count += number;
		 $('.carousel').css({"transform": "translate3d("+count+"%,0px, 0px)"});
	});
	$('#next').click(function(){
		count -= number;
		 $('.carousel').css({"transform": "translate3d("+count+"%,0px, 0px)"});
	});
}
function loadmore(){
	$(function () {
	    $(".career-item").slice(0, 4).show();
	    $("#loads").on('click', function (e) {
	        e.preventDefault();
	        $(".career-item:hidden").slice(0, 4).slideDown();
	        if ($(".career-item:hidden").length == 0) {
	            $("#loads").fadeOut('slow');
	        }
	        // $('html,body').animate({
	        //     scrollTop: $(this).offset().top
	        // }, 1500);
	    });
	});

	$('a[href=#top]').click(function () {
	    $('body,html').animate({
	        scrollTop: 0
	    }, 600);
	    return false;
	});

	$(window).scroll(function () {
	    if ($(this).scrollTop() > 50) {
	        $('.totop a').fadeIn();
	    } else {
	        $('.totop a').fadeOut();
	    }
	});
}
function loadmores(){
	$(function () {
	    $(".par-item").slice(0, 9).show();
	    $("#loadmoress").on('click', function (e) {
	        e.preventDefault();
	        $(".par-item:hidden").slice(0, 9).slideDown();
	        if ($(".par-item:hidden").length == 0) {
	            $("#loadmoress").fadeOut('slow');
	        }

	    });
	});

	$('a[href=#top]').click(function () {
	    $('body,html').animate({
	        scrollTop: 0
	    }, 600);
	    return false;
	});

	$(window).scroll(function () {
	    if ($(this).scrollTop() > 50) {
	        $('.totop a').fadeIn();
	    } else {
	        $('.totop a').fadeOut();
	    }
	});
}
function loadmorenews(){
	$(function () {
	    $(".news-item").slice(0, 7).show();
	    $("#loadss").on('click', function (e) {
	        e.preventDefault();
	        $(".news-item:hidden").slice(0, 4).slideDown();
	        if ($(".news-item:hidden").length == 0) {
	            $("#loadss").fadeOut('slow');
	        }
	        // $('html,body').animate({
	        //     scrollTop: $(this).offset().top
	        // }, 1500);
	    });
	});

	$('a[href=#top]').click(function () {
	    $('body,html').animate({
	        scrollTop: 0
	    }, 600);
	    return false;
	});

	$(window).scroll(function () {
	    if ($(this).scrollTop() > 50) {
	        $('.totop a').fadeIn();
	    } else {
	        $('.totop a').fadeOut();
	    }
	});
}
function hovering(){
	$('.par-item').each(function(){
		$(this).hover(function(){
			$(this).find('#item').css('opacity','1');
		},function(){
			$(this).find('#item').css('opacity','0');
		}
		);
	});
}

function searchProject(){
	$('select').on('change', function(){
	    if ( $(this).attr('id') == 'regions' ) {
			$('#region-input').val( $(this).val() );
		}
	    else if ( $(this).attr('id') == 'divisions' ) {
			$('#division-input').val( $(this).val() );
		}
	    else if ( $(this).attr('id') == 'status' ) {
			$('#status-input').val( $(this).val() );
		}
	    else if ( $(this).attr('id') == 'contract' ) {
			$('#contract-input').val( $(this).val() );
		}
		else{
			$('#region-input').val( null );
			$('#division-input').val( null );	
			$('#status-input').val( null );
			$('#contract-input').val( null );	
		}
	})
}
//Front Page Caroufredsel

function frontCarousel() {

	$('#frontCarousel').carouFredSel({
		responsive: true,
		infinite: true,
		height: "variable",
		items: {
			visible: {
				min: 1,
				max: 2,
			},
		},
		scroll: {
			items: 1,
			fx: "scroll",
			easing: "linear",
			duration: 500,
			pauseOnHover: true,
			event: "click",
		},
		auto: {
			play: false,
			pauseOnHover: true,
		},
		swipe: true,
		prev: {
			button: "#foo2_prev",
			key: "",
		},
		next: {
			button: "#foo2_next",
			key: "",
		},
	});

}

// Company Banner Slider

function companyCarousel() {

	$('#companyCarousel').carouFredSel({
		responsive: true,
		infinite: true,
		height: "variable",
		items: {
			visible: {
				min: 1,
				max: 1,
			},
		},
		scroll: {
			items: 1,
			fx: "scroll",
			easing: "linear",
			duration: 500,
			pauseOnHover: true,
			event: "click",
		},
		auto: {
			play: false,
			pauseOnHover: true,
		},
		swipe: true,
		prev: {
			button: "#comSlider_prev",
			key: "",
		},
		next: {
			button: "#comSlider_next",
			key: "",
		},
	});

}
